import React from "react";
import { Container } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import CustomButton from "../elements/CustomButton";
import Stores from "../blocks/Stores";

const HomeAbout = ({ slice }) => {
  return (
    <div id="about-stores">
      <Container className="about-container bg-light py-5 d-flex flex-column flex-lg-row justify-content-lg-between">
        <div className="about-text">
          <h2 className="pe-5 mb-3">{slice.primary.title}</h2>
          <div className="about-rich-text-container">
            <div className="about-rich-text mb-4 pe-lg-5 pb-5">
              <PrismicRichText field={slice.primary.description.richText} />
            </div>
          </div>
          <CustomButton
            label={slice.primary.link_label}
            url={slice.primary.link.url}
          />
        </div>
        <Stores />
      </Container>
    </div>
  );
};

export default HomeAbout;
